import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import CodeIcon from "@material-ui/icons/Code";
import PolicyIcon from "@material-ui/icons/Policy";
import AttachmentIcon from "@material-ui/icons/Attachment";
import { CodeEditor } from "../shared/codeEditor";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import { putPaste } from "../shared/pasteService";
import { mapStateToDto } from "../shared/mappers";
import { PrivacySettings } from "./privacySettings";
import { CreatePasteBackdrop } from "./createPasteBackdrop";

const useStyles = makeStyles({
  root: {},
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export const CreatePasteComponent = () => {
  const classes = useStyles();
  const history = useHistory();

  const [inProgress, setInProgress] = useState(false);
  const [codeState, setCode] = useState({
    pasteTitle: "",
    pasteId: uuidv4(),
    code: "",
  });
  const [tabValue, setTabValue] = React.useState(0);
  const [privacyState, setPrivacyState] = React.useState({
    selfDestructEnabled: false,
    passphraseEnabled: false,
    isPrivate: false,
    selfDestructTime: new Date(),
    revealPassphrase: false,
  });
  const [fileUploadState, setFileUploadState] = React.useState({ file: null });

  const handleSubmit = () => {
    setInProgress(true);
    const dto = mapStateToDto(codeState, privacyState, fileUploadState);

    putPaste(dto)
      .then(function () {
        history.push(`/pastes/${dto.id}`);
      })
      .catch(function () {});
    setInProgress(false);
  };

  return (
    <Card className={classes.root} variant="outlined">
      <CreatePasteBackdrop open={inProgress} />
      <CardContent>
        <form
          className="newPastesForm"
          noValidate
          autoComplete="off"
          variant="outlined"
          onSubmit={handleSubmit}
        >
          <TextField
            id="paste-title"
            label="Paste Title"
            variant="outlined"
            value={codeState.pasteTitle}
            onChange={(event) =>
              setCode({ ...codeState, pasteTitle: event.target.value })
            }
            fullWidth
          />
          <br />
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => setTabValue(newValue)}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            aria-label="icon tabs example"
          >
            <Tab icon={<CodeIcon />} label={"Editor"} />
            <Tab icon={<PolicyIcon />} label={"Stay in charge"} />
            <Tab icon={<AttachmentIcon />} label={"File Attachment"} />
          </Tabs>
          <div
            id="editor-panel"
            role={"tabpanel"}
            className={classes.root}
            hidden={tabValue !== 0}
          >
            <CodeEditor readonly={false} state={codeState} setCode={setCode} />
          </div>
          <div
            id="privacy-panel"
            role={"tabpanel"}
            className={classes.root}
            hidden={tabValue !== 1}
          >
            <PrivacySettings
              privacyState={privacyState}
              setPrivacyState={setPrivacyState}
            />
          </div>
          <div
            id="editor-panel"
            role={"tabpanel"}
            className={classes.root}
            hidden={tabValue !== 2}
          >
            File upload
            <input
              type="file"
              name="file"
              onChange={(event) =>
                setFileUploadState({ file: event.target.files[0] })
              }
            />
          </div>
        </form>
      </CardContent>
      <CardActions>
        <Button
          data-testid={"publishButton"}
          size="small"
          onClick={handleSubmit}
        >
          Publish Paste
        </Button>
      </CardActions>
    </Card>
  );
};
