import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { CodeEditor } from "../shared/codeEditor";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { DeletePasteBackdrop } from "./deletePasteBackdrop";
import { getPaste } from "../shared/pasteService";

const useStyles = makeStyles(() => ({
  root: {},
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export const RenderPasteComponent = (props) => {
  const displayedPasteId = props.match.params.pasteId;
  const classes = useStyles();
  const [state, setState] = useState({ code: "" });
  const [deleteBackdropVisible, setDeleteBackdropVisible] = useState(false);

  useEffect(
    (state) => {
      async function fetchData() {
        const result = await getPaste(displayedPasteId);

        console.log("gp", result);

        setState({
          ...state,
          id: result.data.id,
          title: result.data.title,
          created: result.data.created,
          code: result.data.content,
          summary: result.data?.summary?.summary,
        });
      }

      fetchData();
    },
    [displayedPasteId]
  );

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <h2>{state.title}</h2>
        <h3>{state.summary}</h3>
        <br />
        <DeletePasteBackdrop
          pasteId={state.id}
          visible={deleteBackdropVisible === true}
          setVisible={setDeleteBackdropVisible}
        />
        <PasteActionButtons
          setDeleteBackdropVisible={setDeleteBackdropVisible}
        />

        <div id="editor-panel" role={"tabpanel"} className={classes.root}>
          <CodeEditor state={state} readonly={true} />
        </div>
      </CardContent>
    </Card>
  );
};

export const PasteActionButtons = (props) => {
  const handleDeleteButtonClick = () => {
    props.setDeleteBackdropVisible(true);
  };

  return (
    <ButtonGroup
      variant="text"
      color="primary"
      aria-label="text primary button group"
    >
      <Button onClick={handleDeleteButtonClick}>Delete</Button>
    </ButtonGroup>
  );
};
