import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Typography } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import TimelineIcon from "@material-ui/icons/Timeline";
import { Link as RouterLink } from "react-router-dom";
import { fetchMostViewedPastes } from "../shared/pasteService";
import { useTimer } from "../../hooks/useTimer";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
}));

export const MostViewedPastesList = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({ mostViewedPastes: [] });

  const fetchData = async () => {
    const result = await fetchMostViewedPastes();

    if (result.data) {
      const pastes = [...result.data]
        .filter((it) => it.pasteId !== undefined)
        .sort((m, n) => m.numOfImpresssions < n.numOfImpresssions)
        .slice(-10);

      setState({ ...state, mostViewedPastes: pastes });
    }
  };

  useTimer(fetchData, 10000);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <List
      subheader={<ListSubheader>Most viewed Pastes</ListSubheader>}
      className={classes.root}
    >
      <MostViewedPastesListItems mostViewedPastes={state.mostViewedPastes} />
    </List>
  );
};

export const MostViewedPastesListItems = (params) =>
  params.mostViewedPastes
    ? [...params.mostViewedPastes]
        .sort((m, n) => n.numOfImpresssions - m.numOfImpresssions)
        .map((item, idx) => (
          <MostViewedPastesListItem pasteTrendItem={item} key={idx} />
        ))
    : null;

const MostViewedPastesListItem = (params) => {
  const pasteTrendItem = params.pasteTrendItem;

  return (
    <RouterLink to={`/pastes/${pasteTrendItem.pasteId}`}>
      <ListItem>
        <ListItemIcon>
          <TimelineIcon />
        </ListItemIcon>
        <ListItemText
          primary={pasteTrendItem.title}
          xsecondary={pasteTrendItem?.summary?.summary}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                sx={{ color: "text.primary", display: "inline" }}
              >
                <strong>{pasteTrendItem?.summary?.topic}</strong>
              </Typography>
              {pasteTrendItem?.summary?.summary}
            </React.Fragment>
          }
        />

        <ListItemSecondaryAction>
          <Badge
            badgeContent={pasteTrendItem.numOfImpressions}
            max={999}
            color="primary"
          >
            <VisibilityIcon />
          </Badge>
        </ListItemSecondaryAction>
      </ListItem>
    </RouterLink>
  );
};
