import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import TimelineIcon from "@material-ui/icons/Timeline";
import { Link as RouterLink } from "react-router-dom";
import { fetchLatestPates } from "../shared/pasteService";
import { useTimer } from "../../hooks/useTimer";
import Badge from "@material-ui/core/Badge";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
}));

export const RecentlyCreatedPastesList = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({ recentlyCreatedPastes: [] });

  const fetchData = async () => {
    const result = await fetchLatestPates();

    if (result.data) {
      const pastes = [...result.data]
        .filter((it) => it.pasteId !== undefined && it.created !== undefined)
        .sort((m, n) => new Date(n.created) - new Date(m.created))
        .slice(-10);

      setState({ ...state, recentlyCreatedPastes: pastes });
    }
  };

  useTimer(fetchData, 10000);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <List
      subheader={<ListSubheader>Recently created Pastes</ListSubheader>}
      className={classes.root}
    >
      <RecentlyCreatedPastesListItems
        recentlyCreatedPastes={state.recentlyCreatedPastes}
      />
    </List>
  );
};

const RecentlyCreatedPastesListItems = (params) =>
  params.recentlyCreatedPastes
    ? [...params.recentlyCreatedPastes].map((item, idx) => (
        <RecentlyCreatedPastesListItem pasteTrendItem={item} key={idx} />
      ))
    : null;

const RecentlyCreatedPastesListItem = (params) => {
  const pasteTrendItem = params.pasteTrendItem;

  const pasteAge = Math.ceil(
    (new Date() - new Date(pasteTrendItem.created)) / 60000
  );

  return (
    <RouterLink to={`/pastes/${pasteTrendItem.pasteId}`}>
      <ListItem>
        <ListItemIcon>
          <TimelineIcon />
        </ListItemIcon>
        <ListItemText
          primary={pasteTrendItem.title}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                sx={{ color: "text.primary", display: "inline" }}
              >
                <strong>{pasteTrendItem?.summary?.topic}</strong>
              </Typography>
              {pasteTrendItem?.summary?.summary}
            </React.Fragment>
          }
        />
        <Badge>{`${pasteAge} minutes ago`}</Badge>
      </ListItem>
    </RouterLink>
  );
};
