import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useHistory } from "react-router-dom";
import { deletePaste } from "../shared/pasteService";

const useStyles = makeStyles((theme) => ({
  deletePasteBackdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const DeletePasteBackdrop = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = React.useState({
    errorMessage: "",
  });
  const [activeItem, setActiveItem] = React.useState(
    "DeletePasteInputDialogue"
  );

  const handleDeleteIntent = (passphrase) => {
    setActiveItem("DeletePasteSpinner");
    deletePaste(props.pasteId, passphrase)
      .then(function () {
        props.setVisible(false);
        history.push(`/`);
      })
      .catch(function () {
        setState({
          ...state,
          errorMessage:
            "This did not work out. Please check the passphrase and try again.",
        });
        setActiveItem("DeletePasteInputDialogue");
      });
  };
  const handleCloseIntent = () => {
    props.setVisible(false);
  };

  return (
    <Backdrop
      className={classes.deletePasteBackdrop}
      open={props.visible === true}
    >
      {activeItem === "DeletePasteInputDialogue" && (
        <Card>
          <CardContent>
            <DeletePasteInputDialogue
              deleteCallback={handleDeleteIntent}
              cancelCallback={handleCloseIntent}
            />
            {state.errorMessage}
          </CardContent>
        </Card>
      )}
      {activeItem === "DeletePasteSpinner" && <DeletePasteSpinner />}
    </Backdrop>
  );
};

export const DeletePasteInputDialogue = (props) => {
  const [formState, setFormState] = React.useState("");
  const deleteCallback = props.deleteCallback;
  const cancelCallback = props.cancelCallback;

  const handleDelete = () => {
    deleteCallback(formState);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h1>Delete paste</h1>
      </Grid>
      <Grid item xs={12}>
        Please enter your passphrase to delete the paste.
      </Grid>
      <Grid item xs={12}>
        <TextField
          data-testid="passphraseField"
          id="passphraseField"
          label="Passphrase"
          variant="outlined"
          type="password"
          value={formState}
          onChange={(event) => setFormState(event.target.value)}
          color={"primary"}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          data-testid="deleteButton"
          variant="contained"
          color="secondary"
          onClick={handleDelete}
        >
          Delete
        </Button>
        <Button
          data-testid="abortButton"
          variant="contained"
          color="primary"
          onClick={cancelCallback}
        >
          Abort
        </Button>
      </Grid>
    </Grid>
  );
};

const DeletePasteSpinner = () => {
  return (
    <Grid>
      <Grid item xs={12}>
        <CircularProgress color="inherit" />
      </Grid>
    </Grid>
  );
};
