import React, { useState } from "react";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export const PassphraseRevealingInput = (props) => {
  const [revealState, setRevealState] = useState(false);

  return (
    <Input
      id={props.id}
      type={revealState ? "text" : "password"}
      onChange={props.onChange}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => {
              setRevealState(!revealState);
            }}
            onMouseDown={(event) => {
              event.preventDefault();
            }}
          >
            {revealState ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};
